<template>
    <v-app>
    <v-container>

  <div id="app">
    <FileUploader @file-uploaded="processFile" />
    <v-card class="mb-2 mt-2" max-width="500" :text="fileName"></v-card>
    <div v-for="(chunk, index) in chunks" :key="index" class="chunk">
      <v-textarea class="mb-2 mt-2" hide-details readonly v-model="chunk.middleText"></v-textarea>
      <v-btn class="mr-2" size="small"  color="primary" @click="editChunk(index)">Edit Chunk</v-btn>
      <v-btn class="mr-2" size="small"  color="primary" @click="deleteChunk(index)">Delete Chunk</v-btn>
            <v-dialog bg-color="white" width="500" scrollable>
              <template v-slot:activator="{ props }">
                <v-btn class="mr-2" size="small" color="info" v-bind="props" text="View Context and Categories"> </v-btn>
              </template>

              <template v-slot:default="{ isActive }">
                <v-card title="Context Info">
                  <v-list class="ml-4 mr-4" lines="one">
                    <v-list-item
                    title="Categories"
                  >{{ chunk.categories }}</v-list-item>
                  <v-list-item
                    title="Context"
                  >{{ chunk.context }}</v-list-item>
                </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      text="Close"
                      @click="isActive.value = false"
                    ></v-btn>
                  </v-card-actions>
                </v-card>

  </template>
</v-dialog>
    </div>
  </div>
  <ChunkEditor :showModal="openEditChunk" :fullChunk="fullChunk" @updateChunk="updateChunk" />


</v-container>
  </v-app>
</template>

<script>
import FileUploader from './components/FileUploader.vue';
import ChunkEditor from './components/ChunkEditor.vue';

export default {
  name: 'App',
  components: {
    FileUploader,
    ChunkEditor
  },
  data() {
    return {
      fileContent: '',
      fileName: '',
      chunks: [],
      currentChunk: 0,
      fullChunk: {},
      openEditChunk: false,

      // chunk bits:
      precedingText: '',
      topText: '',
      middleText: '',
      bottomText: '',
      followingText: '',
      categories: '',
      context: ''
    };
  },
  methods: {
    processFile(file) {
      const reader = new FileReader();
      this.fileName = file.name;
      reader.onload = (e) => {
        const text = e.target.result;
        this.chunks = [];
        this.fileContent = text; 
        let textChunks = this.chunkTextByWords(text);
        for(let index = 0; index < textChunks.length; index++) {
          let fullChunk = {
            precedingText: index>1 ? textChunks[index-2] : "",
            topText: index>0 ? textChunks[index-1] : "",
            middleText: textChunks[index],
            bottomText: (index<textChunks.length -1) ? textChunks[index+1] : "",
            followingText: (index<textChunks.length -2) ? textChunks[index+2] : "",
            categories: "those are categories",
            context: "this is a context, it's a bit longer so let's see how it looks this is a context, it's a bit longer so let's see how it looks this is a context, it's a bit longer so let's see how it looks ",
          }
          this.chunks.push(fullChunk);
        }
      };
      reader.readAsText(file);
    },
    chunkTextByWords(text, preferredChunkSize = 300, tolerance = 0.1) {
      const tokens = text.split(/([.!?]|\s+)/).filter(token => token.length > 0);
      const chunks = [];
      let chunkWords = [];
      let wordCount = 0;

      for (const token of tokens) {
        const isNewLine = token === '\n';
        const isSpaceOrNewLine = isNewLine || token.trim() === '';
        
        if (!isSpaceOrNewLine) {
          wordCount++;
        }

        chunkWords.push(token);

        // Check if the chunk is within the preferred size and tolerance
        if ((wordCount >= preferredChunkSize && token.match(/[.!?]/)) || wordCount > preferredChunkSize * (1 + tolerance)) {
          chunks.push(chunkWords.join(''));
          chunkWords = []; // Reset chunk
          wordCount = 0; // Reset word count
        }
      }

      // Add any remaining words as the last chunk
      if (chunkWords.length > 0) {
        chunks.push(chunkWords.join(''));
      }
      return chunks;
    },
    editChunk(index) {
        console.log("index: " + index);
        this.openEditChunk = false;
        this.currentChunk = index;
        this.fullChunk = this.chunks[index];
        this.openEditChunk = true;
        console.log("finished edit chunk")
    },
    updateChunk(fullChunk) {
        console.log("arrivato full chunk: ");
        console.log(fullChunk);
        this.openEditChunk = false;
        if(Object.keys(fullChunk).length != 0) {
          this.chunks[this.currentChunk] = fullChunk;
        }
        console.log("show modal a: " + this.openEditChunk);
    },
    deleteChunk(index) {
        console.log("cancella il chunk: " + index);
        this.chunks.splice(index, 1);
    },
  },
};
</script>

<style>
#app {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}
.chunk {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.v-textarea textarea {
    font-size: 0.8em;
    height: 200px;
    white-space: pre-wrap;
  }
  
</style>