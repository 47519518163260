<template>
  <div id="chunkEditor">
    <v-dialog  dense v-model="show" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-toolbar dense floating dark color="primary">
          <v-btn icon dark @click="done(false)" >
            <v-icon>mdi-close</v-icon>
          </v-btn>      
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn

              variant="text"
              @click="done(true)"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
    <v-card class="pl-4 pr-4 pt-2">
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details readonly bg-color="grey" auto-grow v-model="precedingText"></v-textarea>
    </v-row>
    <v-row>
    overlap precedente
    <v-spacer></v-spacer>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('precedingText', 'topText', 1)">Move 1 Words Down</v-btn>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('topText', 'precedingText', 1)">Move 1 Words Up</v-btn>
    </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  v-model="topText"></v-textarea>
    </v-row>
    <v-row>
      chunk
    <v-spacer></v-spacer>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('topText', 'middleText', 1)">Move 1 Words Down</v-btn>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('middleText', 'topText', 1)">Move 1 Words Up</v-btn>
    </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  v-model="middleText"></v-textarea>
    </v-row>
    <v-row>
    overlap successivo
    <v-spacer></v-spacer>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('middleText', 'bottomText', 1)">Move 1 Words Down</v-btn>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('bottomText', 'middleText', 1)">Move 1 Words Up</v-btn>
    </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  v-model="bottomText"></v-textarea>
    </v-row>
    <v-row>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('bottomText', 'followingText', 1)">Move 1 Words Down</v-btn>
    <v-btn color="primary" class="mr-2" size="small" @click="moveText('followingText', 'bottomText', 1)">Move 1 Words Up</v-btn>
    </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  readonly bg-color="grey" v-model="followingText"></v-textarea>
    </v-row>
    <v-row>
    Categories
  </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  v-model="categories"></v-textarea>
    </v-row>
    <v-row>
    Context
  </v-row>
    <v-row>
    <v-textarea class="mb-2 mt-2" hide-details auto-grow  v-model="context"></v-textarea>
    </v-row>
  </v-card>
</v-dialog>


  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  props: {
    fullChunk: {},
    showModal: null
  },
  data() {
    return {
      precedingText: '',
      topText: '',
      middleText: '',
      bottomText: '',
      followingText: '',
      categories: '',
      context: '',
      show: false
    };
  },
  methods: {
    moveText(from, to, wordCount) {
      let fromText = this[from].split(' ');
      let toText = this[to].split(' ');

      // Adjust word count if there aren't enough words
      wordCount = Math.min(fromText.length, wordCount);

      if (from === 'topText' && to === 'middleText' || from === 'middleText' && to === 'bottomText' || from === 'precedingText' && to === 'topText' || from === 'bottomText' && to === 'followingText') {
        // Move words from the end of 'from' to the beginning of 'to'
        let movedWords = fromText.splice(-wordCount, wordCount);
        toText = movedWords.concat(toText);
      } else if (from === 'middleText' && to === 'topText' || from === 'bottomText' && to === 'middleText' || from === 'topText' && to === 'precedingText' || from === 'followingText' && to === 'bottomText') {
        // Move words from the beginning of 'from' to the end of 'to'
        let movedWords = fromText.splice(0, wordCount);
        toText = toText.concat(movedWords);
      }

      // Update the data properties
      this[from] = fromText.join(' ');
      this[to] = toText.join(' ');

      // After moving text, we may need to adjust all areas
      this.$forceUpdate()
    },
    done(save) {
      console.log("done");
      let updateChunk = {}
      if(save) {
        updateChunk = {
          precedingText: this.precedingText,
          topText: this.topText,
          middleText: this.middleText,
          bottomText: this.bottomText,
          followingText: this.followingText,
          categories: this.categories,
          context: this.context
        }
      }
      this.show = false;
      this.$emit('updateChunk', updateChunk);
    }
  },
  watch: { 
            showModal: function(newVal, oldVal) { // watch it
                console.log('showModal changed: ', newVal, ' | was: ', oldVal);
                if(newVal) {
                  this.precedingText = this.fullChunk.precedingText,
                  this.topText = this.fullChunk.topText,
                  this.middleText = this.fullChunk.middleText,
                  this.bottomText = this.fullChunk.bottomText,
                  this.followingText = this.fullChunk.followingText,
                  this.categories = this.fullChunk.categories,
                  this.context = this.fullChunk.context,
                  this.show = true;
                }
            },
        },

};
</script>

<style>


.v-textarea textarea {
    font-size: 0.8em;
    height: 200px;
    white-space: pre-wrap;
  }

</style>