<template>
    <div>
        <v-btn color="success" @click="$refs.inputUpload.click()">Upload File</v-btn>
        <input v-show="false" ref="inputUpload" type="file" @change="handleFileUpload" >
    </div>
  </template>
  
  <script>

  export default {
    name: 'FileUploader',
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
        this.$emit('file-uploaded', file);
      },
    },
  };
  </script>
  